import * as Style from "./styles";
import { Layout, message } from "antd";
import { SideMenu } from "../../components/SideMenu";
import { RoutesApp } from "../../routes/routesApp";

import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { NavMenu } from "../../components/NavMenu";
import * as Icons from "@ant-design/icons";
const { Header, Sider, Content, Footer } = Layout;

export const Home = () => {
  const { getRoutes, isLead } = useAuth();
  const [rotas, setRotas] = useState([]);
  const [screenSize, setScreenSize] = useState("big");

  // useEffect(()=>{
  //     window.innerWidth <= 768 ? setScreenSize("small") : setScreenSize("big");
  //     setRotas((e)=>[{label: "Home", key: "/dashboard",icon:<Icons.HomeOutlined />}])
  //     const dataRoutes  = async() =>{
  //         const data = await getRoutes()
  //         const dataRotas = data.map((data)=>{
  //             const Icon = Icons[data.icone]
  //             return {...data,icon:<Icon/>}
  //         })
  //          setRotas((e)=>[...e, ...dataRotas])
  //      }
  //      dataRoutes()

  // },[])

  useEffect(() => {
    // Ajusta o tamanho da tela
    window.innerWidth <= 768 ? setScreenSize("small") : setScreenSize("big");

    // Define a rota inicial
    setRotas([
      { label: "Home", key: "/dashboard", icon: <Icons.HomeOutlined /> },
    ]);

    const dataRoutes = async () => {
      try {
        // Obtém os dados das rotas
        const data = await getRoutes();

        // Verifica se os dados retornados são válidos
        if (!data || !Array.isArray(data)) {
          console.error("Dados de rotas inválidos:", data);
          message.error(`Dados de rotas inválidos: ${data}`, 3);
          return;
        }

        // Mapeia os dados das rotas para incluir ícones
        const dataRotas = data.map((data) => {
          const Icon = Icons[data.icone];
          return { ...data, icon: <Icon /> };
        });

        // Atualiza o estado das rotas
        setRotas((e) => [...e, ...dataRotas]);
      } catch (error) {
        // Tratamento de erro
        console.error("Erro ao obter as rotas:", error);
      }
    };

    // Chama a função para obter e definir as rotas
    dataRoutes();
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background:
            "linear-gradient(0deg, #346e9c 0%, #346e9c 47%, #346e9c 91%)",
          height: "8rem",
        }}
      >
        <Style.Header>
          <Style.Logo src="/assets/images/img/Multleads_logo.png" />
          {/* <HeaderMenu/>     */}
        </Style.Header>
      </Header>
      {screenSize === "small" && <NavMenu items={rotas} />}
      <Layout hasSider style={{ minHeight: "80vh" }}>
        {screenSize === "big" && (
          <Sider
            breakpoint="lg"
            height="100%"
            collapsedWidth="0"
            style={{
              boxShadow: "-4px 4px 5px #00000012",
              background:
                "linear-gradient(0deg, rgb(249 249 249) 0%, rgb(255 255 255) 47%, rgb(255 255 255) 91%)",
            }}
          >
            <SideMenu items={rotas} />
          </Sider>
        )}
        <Content>
          <Style.Section>
            <RoutesApp />
          </Style.Section>
        </Content>
      </Layout>

      {/* <Footer></Footer> */}
    </Layout>
  );
};
