// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-card-head-title {
     font-style: italic;
     /* color: #1484de; */
     color: #346e9c;
     font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/Article/styles.css"],"names":[],"mappings":"AAAA;KACK,kBAAkB;KAClB,oBAAoB;KACpB,cAAc;KACd,eAAe;AACpB","sourcesContent":[".ant-card-head-title {\n     font-style: italic;\n     /* color: #1484de; */\n     color: #346e9c;\n     font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
