import * as Style from "./styles";
import { useEffect, useState } from "react";

import { Article } from "../../components/Article";
import { LinkButton } from "../../components/LinkButton";
import { Loading } from "../../components/Loading";
import { MdAdd, MdModeEditOutline } from "react-icons/md";
import { Button, Modal, Table, message, Tooltip, Row, Col, Select } from "antd";
import { Link } from "react-router-dom";

import { Messages as MessagesApi } from "../../control/messages/control-messages";
import { Fieldset } from "../../components/Fieldset";
import { DeleteOutlined } from "@ant-design/icons";
import { Company } from "../../control/company/control-company";
import { Controller, useForm } from "react-hook-form";
import { SelectLabel } from "../../components/SelectLabel";

export const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageName, setMessageName] = useState(false);
  const [messageId, setMessageid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reloadMessages, setReloadMessages] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [empresa, setEmpresa] = useState([]);

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const empresas = await getEmpresa();
      setEmpresa(empresas);

      if (empresas && empresas.length > 0) {
        setSelectedOption(empresas[0]);
        const company = empresas[0].value;
        fetchMessagesByCompany(company);
      }

      setLoading(false);
    };

    fetchData();
  }, [reloadMessages]);

  const handleChange = (value) => {
    setSelectedOption(value);
    fetchMessagesByCompany(value);
  };

  const fetchMessagesByCompany = async (companyId) => {
    setLoading(true);
    const messageData = await MessagesApi.listAllByCompany(companyId);

    if (messageData?.status === 200 && messageData?.data?.result?.length) {
      setMessages(messageData.data.result);
    } else {
      setMessages([]);
    }

    setLoading(false);
  };

  const getEmpresa = async () => {
    const EmpresaData = await Company.listCombo();
    if (EmpresaData?.status && EmpresaData.status === 200 && EmpresaData.data) {
      return EmpresaData.arrayCompany;
    }
  };

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
      sorter: (a, b) => a.descricao.length - b.descricao.length,
      sortDirections: ["descend"],
    },
    {
      title: "Empresa",
      dataIndex: "empresa",
    },
    {
      title: "Data de envio",
      dataIndex: "data",
    },
    {
      title: "Hora de envio",
      dataIndex: "hora",
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, record) => (
        <Style.Actions size="small">
          {/* <Link to={`./cadastrausuario/${record.idmensagem}`}><MdModeEditOutline/>Editar</Link> */}
          <Tooltip placement="top" title="Excluir">
            <Button onClick={() => showModal(record)}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Style.Actions>
      ),
    },
  ];

  const data = messages?.map((item) => {
    return {
      key: item.idmensagem,
      empresa: item.empresa.nome,
      descricao: item.descricao,
      data: item.data,
      hora: item.hora,
      actions: (
        <Style.Actions size="small">
          <Link to={`./cadastrausuario/${item.id}`}>
            <MdModeEditOutline />
            Editar
          </Link>
          <Tooltip placement="top" title="Excluir">
            <Button onClick={() => showModal(item.id)}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Style.Actions>
      ),
    };
  });

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const showModal = (data) => {
    setIsModalOpen((open) => !open);
    setMessageid(data?.key);
  };

  const deleteMessage = async (id) => {
    const result = await MessagesApi.delete(id);
    if (result && result.status && result.status == 204) {
      message.success("mansagem excluida.", 2);
      setReloadMessages((prev) => prev + 1);
      setIsModalOpen(false);
    } else {
      message.error(`"Não foi possível excluie o Lead."`, 2);
    }
  };

  if (!messages.length && loading) {
    return <Loading loading={true} />;
  }

  return (
    <Article title="Mensagens">
      <Modal
        style={{ padding: 1 }}
        title={messageName}
        open={isModalOpen}
        onCancel={() => showModal()}
        footer={
          <>
            <Button onClick={() => deleteMessage(messageId)}>Excluir</Button>
            <Button onClick={() => showModal()}>Cancelar</Button>
          </>
        }
      ></Modal>
      <Style.Content>
        <Col span={12}>
          <Controller
            control={control}
            name="idempresa" // Corrigido para "idpais"
            render={({ field }) => (
              <SelectLabel
                field={field}
                text="Empresa"
                // error={errors?.idempresa?.message}
                register={register("idempresa")} // Corrigido para 'idpais'
                options={empresa}
                onChange={(value, date) => {
                  field.onChange(value);
                  handleChange(value);
                }}
              />
            )}
          />
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <LinkButton to="../enviomensagem" color="success">
            &nbsp;
            <MdAdd /> Nova Mensagem
          </LinkButton>
        </Col>
      </Style.Content>
      <Style.Container>
        <Fieldset title={"Mensagens Cadastradas"}>
          <Table
            columns={columns}
            pagination={{ pageSize: 20 }}
            dataSource={data}
            onChange={onChange}
          />
        </Fieldset>
      </Style.Container>
    </Article>
  );
};
