// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-body  div{
    margin: 1px;
    grid-template-columns: repeat(auto-fill, 33%);
}

.ant-modal-footer{
    text-align: center!important;
}`, "",{"version":3,"sources":["webpack://./src/templates/CadastroLead/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,6CAA6C;AACjD;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".ant-modal-body  div{\n    margin: 1px;\n    grid-template-columns: repeat(auto-fill, 33%);\n}\n\n.ant-modal-footer{\n    text-align: center!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
